@tailwind base;
@tailwind components;
@tailwind utilities;

.css-1okebmr-indicatorSeparator{
    display: none;
}

table.customTable tr:nth-child(2){
    background-color: #F9FAFB;
}

.ant-btn-primary{
    background-color: #4F46E5 !important;
}

.ant-upload.ant-upload-drag{
    background:none !important;
     border: none !important;
}

.ant-upload.ant-upload-drag .ant-upload{
    padding: 0 !important;
}
.skeleton{
    background:linear-gradient(90deg,rgba(190,190,190,.2) 25%,rgba(129,129,129,.24) 37%,rgba(190,190,190,.2) 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;

}
@keyframes  ant-skeleton-loading  {
    0%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0 50%;
    }
}
textarea {
    resize: none;
  }